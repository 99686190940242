const APP_CONFIG = {
  MAINTAINANCE: 0,
  MINT: 0,
  SWAP: 1,
  POOL: 1,
  FARM: 1,
  TRADE: 0
}


export default APP_CONFIG
