import { ChainId } from '@uniswap/sdk'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  [ChainId.ROPSTEN]: '',
  [ChainId.KOVAN]: '',
  [ChainId.RINKEBY]: '0x198A609936593Ac5009F1D3fdF27FA9b92316035',
  [ChainId.GÖRLI]: '',
  [ChainId.TESTNET]: '',
  [ChainId.BSC]: '',
  [ChainId.POLYGON]: '0xbEDc44B094d3143AC5247C55d45F333C3942b2Ae',
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
